import './css/Compra.css';
import Header from '../components/Header/header.jsx';
import ListaProductosDisponibles from '../components/Card/ListaProductosDisponibles.jsx';
import Footer from '../components/Footer/Footer.jsx';
import SearchForm from '../components/Form/SearchForm.jsx';
import { SearchFormProvider } from '../components/Context/SearchFormContext.jsx';
import Slide from '../components/Card/ImageSlider';  // Importa el Slider
const Tienda = () => {
  return (
    <SearchFormProvider>
      <div className="contenedorCompra">
        <Header scrollPosition={1} className="fixed-header" />
        <div className="cuerpoCompra">
          <div className='flex flex-col w-full items-center'>
            {/* Aquí va el slider */}
            <Slide tipo="Inicio2" />

            {/* Sección del formulario de búsqueda */}
            <div className='bg-gray-100 mt-10 w-full flex flex-col items-center'>
              <SearchForm />
            </div>

            {/* Lista de productos */}
            <ListaProductosDisponibles />
          </div>
        </div>
        <div className='outer-div-Footer'>
          <Footer />
        </div>
      </div>
    </SearchFormProvider>
  );
};

export default Tienda;
