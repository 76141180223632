import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './pages/inicio';
import Tienda from './pages/Tienda';
import FormularioPago from './pages/FormularioPago';
import { ShoppingCartProvider } from './components/Context/ShoppingCartContext';
import './App.css';
import ResetURL from './components/Common/ResetUrl';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useLocation } from 'react-router-dom';
import PagoExitoso from './pages/PagoExitoso';
import PagoPendiente from './pages/PagoPendiente';
import PagoFallido from './pages/PagoFallido';
//import Anfitrion from './pages/Anfitrion';
import Blog from './pages/Blog';
import Cart from './pages/Cart';
import { CartProvider } from './components/Context/cart'
import { NotificationProvider } from './components/Context/notification'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ pathname ]);

  return null;
};

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1000,
    });
  }, []);

  return (
    <Router> {/* Router envuelve todo */ }
      <ScrollToTop />
      <ShoppingCartProvider>
        <CartProvider>
          <NotificationProvider>
            <div className="App font-poppins">
              <Routes>
                <Route path="/" element={ <Inicio /> } />
                <Route path="/Tienda" element={ <Tienda /> } />
                <Route path="/Tienda/Carrito" element={ <Cart /> } />
                <Route path="/Compra/FormularioPago" element={ <FormularioPago /> } />
                <Route path="/PagoExitoso" element={ <PagoExitoso /> } />
                <Route path="/PagoPendiente" element={ <PagoPendiente /> } />
                <Route path="/PagoFallido" element={ <PagoFallido /> } />
                {/* <Route path="/Anfitrion" element={ <Anfitrion /> } /> */ }
                <Route path="/Blog" element={ <Blog /> } />
                <Route path="*" element={ <ResetURL /> } />
              </Routes>
            </div>
          </NotificationProvider>
        </CartProvider>
      </ShoppingCartProvider>
    </Router>
  );
}

export default App;

// pull request