import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './css/SidebarCart.css';
import ProductCart from '../Cart/ProductCart';
import OrderSumary from '../Cart/OrderSumary';
import { useCart } from '../Hooks/useCart';
import { useNotification } from '../Hooks/useNotification';

const SidebarCart = () => {
  const { cart } = useCart();
  const { clearNotification } = useNotification();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    clearNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSidebar = (event) => {
    event.stopPropagation(); // Previene la propagación del clic al document.body
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeSidebar = (event) => {
    // Si no se hace clic en el contenedor del sidebar, cerrarlo
    if (!event.target.closest('.sidebar-cart-container')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      closeSidebar(event);
    };

    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Botón del carrito */}
      <div className={`sidebar ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        <i className="fas fa-shopping-cart carrito-icon"></i>
        {!isOpen && (
          <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-3 -right-2 dark:border-gray-900" />
        )}
      </div>

      {/* Sidebar */}
      <CSSTransition in={isOpen} timeout={200} classNames="sidebar-cart" unmountOnExit>
        <div className="sidebar-cart-background" onClick={(e) => e.stopPropagation()}>
          <div className="sidebar-cart-container">
            <div className="sidebar-cart-header">
              <h2 className="text-xl font-bold tracking-tight text-gray-600 uppercase dark:text-white">
                Carrito
              </h2>
            </div>

            <div className="sidebar-cart-body">
              {cart.length > 0 ? (
                <>
                  <div className="product-list">
                    {cart.map((product) => (
                      <ProductCart key={product.id} item={product} />
                    ))}
                  </div>
                  <div className="order-summary">
                    <OrderSumary />
                  </div>
                  <button className="pay-button">Pagar</button>
                </>
              ) : (
                <p className="text-gray-600">Tu carrito está vacío.</p>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default SidebarCart;
