import React from 'react';
import SidebarCart from '../Sidebar/SidebarCart'; // Importa el componente SidebarHeader 
import './css/header.css'; // Importa el archivo de estilos CSS
import Cart from '../../pages/Cart';


const Header = ({ scrollPosition }) => {
  const headerClass =  'header-solid';
  const logoWhite = "https://ezcbvdobzemkdzwavevc.supabase.co/storage/v1/object/public/lalocadelasplantas/logos/Tienda%20Digital%20(6).png";
  const logoBlack = "https://ezcbvdobzemkdzwavevc.supabase.co/storage/v1/object/public/lalocadelasplantas/logos/Tienda%20Digital%20(5).png";

  const logo = logoBlack ; // Selecciona el logo en función de scrollPosition

  return (
    <header className={headerClass}>
        <div className="logo-container-header">
          <a href="/">
            <img src={logo} alt="Logo de la empresa" className="logo" />
          </a>
        </div>
          <nav className="nav-container-header">
            <ul>
              <li><a href="/tienda">TIENDA</a></li>
              <li><a href="/asesoria">ASESORIA</a></li>
              <li><a href="/espacios">ESPACIOS</a></li>
              <li><a href="/talleres">TALLERES</a></li>
            </ul>
          </nav>
      <div className="button-container">
        <div className="sidebar-container-header">
          <SidebarCart />
        </div>
      </div>
    </header>
  );
};

export default Header;
